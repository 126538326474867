<template>
  <div>
    <!--begin::customer-->
    <b-card class="mb-5" no-body>
      <b-card-header class="p-1" header-tag="header" role="tab">
        <b-button v-b-toggle.accordion-2 block class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon"
                  variant="link">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" visible>
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">
              
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="customers">{{ $t('payments_sales_report.customer') }}</label>
                <multiselect v-model="customers_ids"
                             :multiple="true"
                             :options="customers"
                             :placeholder="$t('payments_sales_report.customer')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="fullname"
                             track-by="id"
                             @input="getIds('customer')"
                             @search-change="getCustomers($event)">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('payments_sales_report.branch') }}</label>
                <multiselect v-model="branches_ids"
                             :placeholder="$t('payments_sales_report.branch')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             :multiple="true"
                             @input="getIds('branch')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>


              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('payments_sales_report.employee') }}</label>
                <multiselect v-model="employees_ids"
                             :multiple="true"
                             :options="users"
                             :placeholder="$t('payments_sales_report.employee')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             @input="getIds('user')"
                             label="name"
                             track-by="id">
                </multiselect>
              </div>


              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('payments_sales_report.collected_by') }}</label>
                <multiselect v-model="collectors_ids"
                             :multiple="true"
                             :options="users"
                             :placeholder="$t('payments_sales_report.collected_by')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             @input="getIds('collected_by')"
                             label="name"
                             track-by="id">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('payments_sales_report.from_date') }}</label>
                <input id="from_date" v-model="filters.from_date" class="form-control" type="date">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('payments_sales_report.to_date') }}</label>
                <input id="to_date" v-model="filters.to_date" class="form-control" type="date">
              </div>
              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_date') }}</b-button>
                    <b-button @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_month') }}</b-button>
                    <b-button @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_year') }}</b-button>
                    <b-button @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.financial_year') }}</b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>

                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.yesterday') }}</b-button>
                    <b-button @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_month') }}</b-button>
                    <b-button @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_year') }}</b-button>
                  </b-button-group>

                </div>
              </div>
              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button id="m_search" class="btn btn-primary mt-auto mr-2" type="button" @click="doFilter">
                  {{ $t('display_report') }}
                </button>
                <button id="resetFilter" class="btn btn-danger mt-auto mr-2" type="button" @click="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <div class="card card-custom">
      <div class="card-body">
        <b-tabs content-class="mt-3">
          <b-tab :active="tab == 'summery' || tab == null" :title="$t('summery')">
            <div class="row mb-10">
              <div id="summaryTableTitle" class="col-md-12 mt-10">
                <h4 class="text-center">{{ $t('MENU.sales_payments_report') }}</h4>
                <h5 v-if="filters.from_date && filters.to_date" class="text-center">{{ $t('from') }}
                  {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
              </div>
            </div>
            <div class="row mb-10">
              <div class="col-12">
                <div class="table-responsive">
                  <div class="row justify-content-end p-4">
                    <b-dropdown :text="$t('export')" class="mr-2 ml-2" right>
                      <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">
                        <span><i class="fa fa-file-excel"></i> {{ $t('excel') }}</span>
                      </button>
                      <button class="dropdown-item" @click="printData('summaryTable', 'print')">
                        <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="printPDFData('summaryTable', 'pdf')">
                        <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                      </button>
<!--                      <button class="dropdown-item" @click="getAllDataForExportSummery('sectionForExportSummary', 'excel')">-->
<!--                        <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>-->
<!--                      </button>-->
                      <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">
                        <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                      </button>
                    </b-dropdown>
                  </div>
                  <table class="table table-bordered" id="summaryTable">
                    <thead>
                    <tr>
                      <th>{{ $t('payments_sales_report.' + periodLabel) }}</th>
                      <th>{{ $t('payments_sales_report.paid_amount') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, index) in data_report_summary" :key="'dataReport'+index">
                      <td>{{ row.label }}</td>
                      <td>{{ row.sum }}</td>
                    </tr>

                    </tbody>
                    <tfoot>
                    <tr>
                      <td>{{ $t('payments_sales_report.total') }}</td>
                      <th>{{ summary_total_sum }}</th>
                    </tr>
                    </tfoot>
                  </table>

                </div>

              </div>
<!--              <div v-if="page_summery" class="col-12 text-center">-->
<!--                <button class="btn btn-warning" @click="loadMoreSummery">-->
<!--                  <p class="mb-0">-->
<!--                    <b-icon font-scale="1" icon="arrow-counterclockwise"></b-icon>-->
<!--                    {{ $t('load_more') }}-->
<!--                  </p>-->
<!--                </button>-->
<!--              </div>-->

            </div>
          </b-tab>

          <b-tab :active="tab == 'details'" :title="$t('details')">
            <div class="mt-3">
              <div class="row mb-10">
                <div id="DetailsTableTitle" class="col-md-12 mt-10">
                  <h4 class="text-center">{{ $t('MENU.sales_payments_report') }}</h4>
                  <h5 v-if="filters.from_date && filters.to_date" class="text-center">{{ $t('from') }}
                    {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
                </div>
              </div>
              <div class="row mb-10">
                <div class="col-12">
                  <div class="table-responsive">
                    <div class="row justify-content-end p-4">
                      <b-dropdown :text="$t('export')" class="mr-2 ml-2" right>
                        <button class="dropdown-item" @click="convertTableToExcelReport('DetailsTable')">
                          <span><i class="fa fa-file-excel"></i> {{ $t('excel') }}</span>
                        </button>
                        <button class="dropdown-item" @click="printData('DetailsTable', 'print')">
                          <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="printPDFData('DetailsTable', 'pdf')">
                          <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="getAllDataForExport('sectionForExportDetails', 'excel')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                        </button>
                      </b-dropdown>
                    </div>
                    <table class="table table-bordered" id="DetailsTable">
                      <thead>
                      <tr>
                        <th>{{ $t('payments_sales_report.payment_code') }}</th>
                        <th>{{ $t('payments_sales_report.invoice_code') }}</th>
                        <th>{{ $t('payments_sales_report.date') }}</th>
                        <th v-if="filters.group_by != 'customer'">{{ $t('payments_sales_report.customer') }}</th>
                        <th v-if="filters.group_by != 'employee'">{{ $t('payments_sales_report.employee') }}</th>
                        <th>{{ $t('payments_sales_report.payment_method') }}</th>
                        <th>{{ $t('payments_sales_report.paid_amount') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template v-for="(row, index) in data_report_details">

                        <tr>
                          <td :colspan="filters.group_by == 'customer' || filters.group_by == 'employee'? 6 : 7">{{ row.label }}</td>
                        </tr>
                        <tr v-for="(row2, index2) in row.items" :key="'axnnn'+getRandom()+index2+index">
                          <td>{{ row2.payment_code }}</td>
                          <td>{{ row2.invoice_code }}</td>
                          <td>{{ row2.date }}</td>
                          <td v-if="filters.group_by != 'customer'">{{ row2.customer_name }}</td>
                          <td v-if="filters.group_by != 'employee'">{{ row2.employee_name }}</td>
                          <td>{{ row2.payment_method_name }}</td>
                          <td>{{ row2.paid_amount }}</td>
                        </tr>
                        <tr>
                          <td :colspan="filters.group_by == 'customer' || filters.group_by == 'employee'? 5 : 6">{{ $t('payments_sales_report.total') }}</td>
                          <td>{{ row.paid_amount }}</td>
                        </tr>
                      </template>

                      </tbody>
                      <tfoot>
                      <tr>
                        <td :colspan="filters.group_by == 'customer' || filters.group_by == 'employee'? 5 : 6"><b>{{ $t('payments_sales_report.total') }}</b></td>
                        <th>{{ details_total_sum }}</th>
                      </tr>
                      </tfoot>
                    </table>
                  </div>

                </div>
              </div>
              <div v-if="page" class="col-12 text-center">
                <button class="btn btn-warning" @click="loadMore">
                  <p class="mb-0">
                    <b-icon font-scale="1" icon="arrow-counterclockwise"></b-icon>
                    {{ $t('load_more') }}
                  </p>
                </button>
              </div>

            </div>
          </b-tab>
          <template #tabs-end>
            <li class="nav-item  d-flex my-auto">
              <select id="" v-model="filters.group_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none" name="">
                <option :value="null" disabled>{{ $t('select_filter') }}</option>

                <option value="date_daily">{{ $t('daily') }}</option>
                <option value="date_weekly">{{ $t('weekly') }}</option>
                <option value="date_monthly">{{ $t('monthly') }}</option>
                <option value="date_yearly">{{ $t('yearly') }}</option>
                <option value="customer">{{ $t('customer') }}</option>
                <option value="employee">{{ $t('employee') }}</option>
                <option value="payment_method">{{ $t('payment_method') }}</option>
                <option value="collector">{{ $t('collected_by') }}</option>
              </select>
            </li>
          </template>
          <!-- <b-tab> -->
          <!-- </b-tab> -->
        </b-tabs>
      </div>
    </div>


    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered" id="sectionForExportSummary">
          <thead>
          <tr>
            <th>{{ $t('payments_sales_report.' + periodLabel) }}</th>
            <th>{{ $t('payments_sales_report.paid_amount') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in dataListSummary" :key="'dataList'+index">
            <td>{{ row.label }}</td>
            <td>{{ row.sum }}</td>
          </tr>

          </tbody>
          <tfoot>
          <tr>
            <td>{{ $t('payments_sales_report.total') }}</td>
            <th>{{ dataList_total_sumSummary }}</th>
          </tr>
          </tfoot>
        </table>

        <table class="table table-bordered" id="sectionForExportDetails">
          <thead>
          <tr>
            <th>{{ $t('payments_sales_report.payment_code') }}</th>
            <th>{{ $t('payments_sales_report.invoice_code') }}</th>
            <th>{{ $t('payments_sales_report.date') }}</th>
            <th v-if="filters.group_by != 'customer'">{{ $t('payments_sales_report.customer') }}</th>
            <th v-if="filters.group_by != 'employee'">{{ $t('payments_sales_report.employee') }}</th>
            <th>{{ $t('payments_sales_report.payment_method') }}</th>
            <th>{{ $t('payments_sales_report.paid_amount') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in dataList">

            <tr>
              <td :colspan="filters.group_by == 'customer' || filters.group_by == 'employee'? 6 : 7">{{ row.label }}</td>
            </tr>
            <tr v-for="(row2, index2) in row.items" :key="'axnnn'+getRandom()+index2+index">
              <td>{{ row2.payment_code }}</td>
              <td>{{ row2.invoice_code }}</td>
              <td>{{ row2.date }}</td>
              <td v-if="filters.group_by != 'customer'">{{ row2.customer_name }}</td>
              <td v-if="filters.group_by != 'employee'">{{ row2.employee_name }}</td>
              <td>{{ row2.payment_method_name }}</td>
              <td>{{ row2.paid_amount }}</td>
            </tr>
            <tr>
              <td :colspan="filters.group_by == 'customer' || filters.group_by == 'employee'? 5 : 6">{{ $t('payments_sales_report.total') }}</td>
              <td>{{ row.paid_amount }}</td>
            </tr>
          </template>

          </tbody>
          <tfoot>
          <tr>
            <td :colspan="filters.group_by == 'customer' || filters.group_by == 'employee'? 5 : 6"><b>{{ $t('payments_sales_report.total') }}</b></td>
            <th>{{ dataList_total_sum }}</th>
          </tr>
          </tfoot>
        </table>

      </div>
    </div>
    <div id="selectorExport" class="selector-export"></div>
    <!--end::customer-->

    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'SalesPaymentsReport'"></report-main-header>
      </div>
    </div>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import TableToExcel from "@linways/table-to-excel";
import {mapGetters} from "vuex";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";

export default {
  name: "index-payment-of-sales-report",
  components: {ReportMainHeader},
  data() {
    return {
      mainRoute: '/reports/sales/payments',
      mainRouteDependency: 'base/dependency',

      dataList: [],
      dataList_total_sum: 0,

      dataListSummary: [],
      dataList_total_sumSummary: 0,

      data_report_details: [],
      details_total_sum: 0,

      data_report_summary: [],
      summary_total_sum: null,



      filters: {
        f_collectors_ids: [],
        f_employees_ids: [],
        f_customers_ids: [],
        f_branches_ids: [],

        // customer_id: null,
        // branch_id: null,
        from_date: null,
        to_date: null,
        user_id: null,
        // collected_user_id: null,
        group_by: 'date_daily',

        period: null,
        f_time_period: 0,
        range: null,
      },

      data: [],
      customers: [],
      users: [],
      periodLabel: 'day',
      customer: null,
      user: null,
      collected_by: null,
      branches: [],
      branch: null,

      collectors_ids: [],
      employees_ids: [],
      customers_ids: [],
      branches_ids: [],

      tab: this.$route.query.tab ? this.$route.query.tab : 'summery',
      page: 1,
      page_summery: 1,

    }
  },
  watch: {
    "filters.group_by": function (val) {
      if (val)
        this.doFilter();
      switch (val) {
        case 'date_daily':
          this.periodLabel = 'day';
          break;
        case 'date_weekly':
          this.periodLabel = 'week';
          break;
        case 'date_monthly':
          this.periodLabel = 'month';
          break;
        case 'date_yearly':
          this.periodLabel = 'year';
          break;
        case 'customer':
          this.periodLabel = 'customer';
          break;
        case 'employee':
          this.periodLabel = 'employee';
          break;
        case 'payment_method':
          this.periodLabel = 'payment_method';
          break;
        case 'collector':
          this.periodLabel = 'collected_by';
          break;

      }
    },
    // customer: function (val) {
    //   if (val) {
    //     this.filters.customers = val.id;
    //   } else {
    //     this.filters.customers = null;
    //   }
    // },
    user: function (val) {
      if (val) {
        this.filters.user_id = val.id;
      } else {
        this.filters.user_id = null;
      }
    },
    collected_by: function (val) {
      if (val) {
        this.filters.collected_user_id = val.id;
      } else {
        this.filters.collected_user_id = null;
      }
    },
  },
  computed: {

    ...mapGetters(["currentUserPersonalInfo"]),
    isSystemUser: function () {
      return this.currentUserPersonalInfo.is_system_user
    },


  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.sales_reports"),
      route: '/reports/sales-reports-links'
    }, {title: this.$t("MENU.sales_payments_report")}]);
    this.filters.group_by = this.$route.query.filter ? this.$route.query.filter : 'date_daily';
  },
  created() {
    this.getBranches();
    this.getUsers();
  },
  methods: {
    ...cssStypeForPrintReport,
    getReportInvoiceDetails() {
      let _limit = (this.page ? this.page : 1) * 15
      ApiService.get(`${this.mainRoute}/details`, {
        params: {...this.filters, limit: _limit}
      }).then((response) => {
        this.data_report_details = response.data.data.data;
        this.details_total_sum = response.data.data.totals.paid_amounts;
      });
    },
    getReportInvoiceSummery() {
      let _limit = (this.page_summery ? this.page_summery : 1) * 5
      ApiService.get(`${this.mainRoute}/summary`, {
        params: {...this.filters, limit: _limit}
      }).then((response) => {
        this.data_report_summary = response.data.data.data;
        this.summary_total_sum = response.data.data.totals.paid_amounts;
      });
    },
    getAllDataForExport(tableId, _export) {

      ApiService.get(this.mainRoute, {params: {...this.filters, is_export: 1, limit: 99999999999999999999}}).then((response) => {
        this.dataList = response.data.data.data;
        this.dataList_total_sum = response.data.data.totals.paid_amounts;
        // if (_export == 'pdf' || _export == 'print')
        //   this.printData(tableId, _export);
        // if (_export == 'excel')
          this.convertTableToExcelReport(tableId);
      });
    },
    getAllDataForExportSummery(tableId, _export) {

      ApiService.get(`${this.mainRoute}/summary`, {params: {...this.filters, is_export: 1, limit: 99999999999999999999}}).then((response) => {
        this.dataListSummary = response.data.data.data;
        this.dataList_total_sumSummary = response.data.data.totals.paid_amounts;
        // if (_export == 'pdf' || _export == 'print')
        //   this.printData(tableId, _export);
        // if (_export == 'excel')
          this.convertTableToExcelReport(tableId);
      });
    },
    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'sales payments report');
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('MENU.sales_payments_report') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },
    changeFinancialYear() {
      if (this.filters.financial_year == 1)
        this.filters.financial_year = 0;
      else
        this.filters.financial_year = 1;
    },
    changeIntervalType(action_type, range = null) {
      if (this.filters.period == action_type) {
        this.filters.period = null;
        this.filters.f_time_period = 0;
      } else {
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }
    },

    getIds(type) {
      switch (type) {
        case 'branch':
          // this.filters.branch_id = this.branch ? this.branch.id : null;
          this.filters.f_branches_ids = this.branches_ids.map((row)=>row.id);
          break;
        case 'user':
          // this.filters.user_id = this.user ? this.user.id : null;
          this.filters.f_employees_ids = this.employees_ids.map((row)=>row.id);
          break;
        case 'collected_by':
          // this.filters.collected_user_id = this.collected_by ? this.collected_by.id : null;
          this.filters.f_collectors_ids = this.collectors_ids.map((row)=>row.id);
          break;
        case 'customer':
          // this.filters.customer_id = this.customer ? this.customer.id : null;
          this.filters.f_customers_ids = this.customers_ids.map((row)=>row.id);
          break;
      }
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    setCustomers(val) {
      if (val) {
        // this.filters.customers = val.map((row) => row.id);
        this.filters.customer_id = val.id;
      } else {
        this.filters.customer_id = null;
      }
    },


    getRandom() {
      return Math.floor(Math.random() * 10000);
    },

    doFilter() {
      this.page_summery = 1;
      this.page = 1;
      this.getReportInvoiceSummery();
      this.getReportInvoiceDetails();

    },
    resetFilter() {
      this.filters.customer_id = null;
      this.filters.branch_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.user_id = null;
      this.filters.collected_user_id = null;
      this.filters.period = null;
      this.filters.f_time_period = 0;
      this.filters.range = null;

      this.customer = null;
      this.user = null;
      this.collected_by = null;
      this.branch = null;


      this.filters.f_branches_ids = [];
      this.branches_ids = [];
      this.filters.f_employees_ids = [];
      this.employees_ids = [];
      this.filters.f_collectors_ids = [];
      this.collectors_ids = [];
      this.filters.f_customers_ids = [];
      this.customers_ids = [];

      this.doFilter();

    },
    async getCustomers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/customers", {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
    },
    getUsers() {
        ApiService.get(this.mainRouteDependency + "/users").then((response) => {
          this.users = response.data.data;
        });
    },


    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getReportInvoiceDetails();
    },
    loadMoreSummery() {
      this.page_summery = this.page_summery ? (this.page_summery + 1) : 1;
      this.getReportInvoiceSummery();
    },
    /*****
     * details
     */


    /**
     * get for summary tab
     */
    printData(tableId) {
      this.setReportLog('pdf', 'payments sales report');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },
    printPDFData(tableId, type) {
      this.setReportLog('pdf', 'payments sales report');

      // let divToPrint = document.getElementById(tableId);
      // let divTitleToPrint = document.getElementById(tableId + 'Title');
      // let newWin = window.open("");
      // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      // setTimeout(() => {
      //     newWin.print();
      //     newWin.close();
      // }, 100)
      exportPDFInnerAction(tableId, type, this.$t('MENU.sales_payments_report'), this.$t('MENU.sales_payments_report'));
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }

  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}
</style>
